import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './perpage.scss';

function PerPage(props:any){
    const {currentPage, setCurrentPage, paginateResults, totalPages} = props;
    const currPage = paginateResults.current_page;
    const perPage = paginateResults.per_page;
    const totalResults = paginateResults.total;

    const startResult = (currPage - 1) * perPage + 1;
    const endResult = Math.min(currPage * perPage, totalResults);
     const handlePreviousPage = (props:any) => {
        if (currentPage > 1) {
            return setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            const curr = currentPage + 1;
            return setCurrentPage(curr);
        }
    };
    return(
    <div className=' col-12 pagination d-flex justify-content-between align-items-center' >
    <button className="iconButton" onClick={handlePreviousPage} disabled={currentPage === 1 }>
        <FontAwesomeIcon icon={faChevronLeft} />
    </button>
    <div><span> {startResult} to {endResult} of {totalResults} Results</span></div>

    <button className="iconButton" onClick={handleNextPage} disabled={currentPage === totalPages }>
        <FontAwesomeIcon icon={faChevronRight} />
    </button>

    </div>
    )
}

export default PerPage;