import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";

import './newpassword.scss';
import { resetPassword } from "../../Services/clientService";
function NewPassword(){

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    setValue,
  } = useForm();

  const location = useLocation();

  useEffect(()=>{
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const email = params.get('email');

    if (email) {
      setValue('email', email);
    }
    if (token) {
      setValue('token', token);
    }
  },[setValue]);
  const [msg, setMessage] = useState("")
  const [showToaster, setShowToaster] = useState(false);
  const Navigate = useNavigate();
  const notify = (message: string) => {
    setShowToaster(true);
    toast.success(message, {
        onClose: () => Navigate("/login") // Navigate to the "/" page when the toaster is closed
    });
  };

  const onSubmit = async (formData: any) => {
    resetPasswordAction(formData);
  };

  const resetPasswordAction = async (formData: any) => {
    try {
      const res = (await resetPassword(formData)) as any;
      const success = res.success;
      if(success){
        console.log(res.message);
        setMessage(res.message);
        notify(res.message);
      }else {
        for (const field in res.errors) {
          setError(field, {
            type: 'manual',
            message: res.errors[field][0],
          });
        }
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

    return(
        <>
            <div className="login-form resetPassword pl-lg-4">
            <div className="form-inputs_header">
              <h2 className="form-input_title text-center">Reset your password</h2>
              <p>Fill in the details below</p>
            </div>
             <form onSubmit={handleSubmit(onSubmit)}>
                 <div className="imgcontainer">
                   <img
                     src="https://img1.freepng.fr/20180331/ffe/kisspng-computer-icons-user-clip-art-user-5abf13dad7aed4.5909364715224718988835.jpg"
                     alt="Avatar"
                     className="avatar"
                   />
                 </div>
                 <div className="form-group">
                   <input
                     type="password"
                     placeholder="New Password"
                     {...register("password", {
                       required: "Password is required",
                     })}
                   />
                   <span className="input-icon">
                     <FontAwesomeIcon icon={faLock} />
                   </span>
                 </div>
                 <input type="hidden" 
                   {...register('email')}
                 />
                 <input type="hidden" 
                   {...register('token')}
                 />
                 {errors.password && typeof errors.password.message === "string" && (
                   <p className="error">{errors.password.message}</p>
                 )}
               <div className="form-group">
                   <input type="password" 
                   placeholder="Confirm Password" 
                   {...register('confirm_password', {
                 required: 'Confirm Password is required' })}
                 />
                <span className="input-icon">
                 <FontAwesomeIcon icon={faLock} />
                 </span>
               </div>
               {errors.confirm_password && typeof errors.confirm_password.message === 'string' && <p className='error'>{errors.confirm_password.message}</p>}
               <NavLink className="nav-link text-primary m-3 text-center" to="/login">I remember my login details</NavLink>
                 <button className="login-btn">Submit</button>
                 {msg && <p className="mt-2"><span className="forget-msg">{msg}</span></p>}
             </form>
             {showToaster && <ToastContainer />}
           </div>
        </>
    )
}

export default NewPassword;