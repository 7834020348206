import React, {
  useEffect,
  // useState
} from "react";
import { useNavigate } from "react-router-dom";

import "./login.css";
import LoginForm from "./LoginForm";
import LoginHeader from "./LoginHeader";
import SocialLogin from "./SocialLogin";
import SeparatorCom from "./SeparatorCom";
import LoginForgetPasswordCom from "./LoginForgetPasswordCom";
import SignUpHereCom from "./SignUpHereCom";


function LoginComponent() {
  
  const Navigate = useNavigate();
  // const [errorMessage, setErrorMessage] = useState("");
  

  useEffect(() => {
    if (localStorage.getItem("token")) {
      Navigate("/");
    }
  }, []);

  return (
    <div className="login-form pl-lg-4">
      <LoginHeader/>
      <LoginForm/>
      <LoginForgetPasswordCom/>
      <SignUpHereCom/>
      <SeparatorCom/>
      <SocialLogin/>
    </div>
  );
}

export default LoginComponent;
