// import React from 'react'
import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useNavigate} from 'react-router-dom';
import axios from 'axios';
// import MapBox from '../../../components/Masjid/MapCard/MapBox';
// import customIcon from '../../../components/Masjid/MapCard/images/iconDef.png'
import L from 'leaflet';

import AddMasjidForm from '../../components/AddMasjid/AddMasjidForm';
function AddMasjid() {
    const placeholderMasjids = [
        {
          id: 1,
          latitude: 25.423821,
          longitude: 77.657871,
          name: 'Placeholder Masjid',
          address: '123 Placeholder St',
          distance_to_masjid: 100,
          image: 'placeholder.jpg', // Replace with actual image URL
        },
    ]


    const center = {
      lat:25.423821, 
      lng: 77.657871,
    };
    
    function DraggableMarker() {
      const [draggable, setDraggable] = useState(false);
      const [position, setPosition] = useState(center);
      const [placeName, setPlaceName] = useState('');
      const markerRef = useRef(null);
      const Navigate = useNavigate();   
    
      const eventHandlers = useMemo(
        () => ({
          dragend() {
            const marker = markerRef.current;
            if (marker != null) {
                const newPosition = marker.getLatLng();
                setPosition(newPosition);
                fetchPlaceName(newPosition.lat, newPosition.lng);
            }
          },
        }),
        []
      );
    
      const toggleDraggable = useCallback(() => {
        setDraggable((d) => !d);
      }, []);
    
      const customIcon = L.icon({
        iconUrl: 'https://cdn-icons-png.flaticon.com/512/619/619087.png', // Replace with your icon path
        iconSize: [32, 32], // Adjust icon size as needed
      });

      const fetchPlaceName = async (lat, lng) => {
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`
          );
          const { display_name } = response.data;
          setPlaceName(display_name);
        } catch (error) {
          console.error('Error fetching place name:', error);
        }
      };
        
    
      useEffect(() => {
        if (!localStorage.getItem('token')) {
          Navigate('/login')
        }
        // Fetch the initial place name when the component mounts
        fetchPlaceName(position.lat, position.lng);
      }, []);
    
      return (
        <Marker
          draggable={draggable}
          eventHandlers={eventHandlers}
          position={position}
          ref={markerRef}
          icon={customIcon} // Set the custom icon for the marker
        >
        <Popup minWidth={150}>
            <span>
            Place: {placeName || 'Fetching...'}<br />
            Latitude: {position.lat.toFixed(6)}<br />
            Longitude: {position.lng.toFixed(6)}
            </span>
            <br />
            <span onClick={toggleDraggable}>
            {draggable
                ? 'Marker is draggable'
                : 'Click here to make marker draggable'}
            </span>
        </Popup>
        </Marker>
      );
    }
      
  return (
    <div className='row' style={{margin:'0'}}>
        <div className='col-lg-6' style={{padding:'0'}}>
            <AddMasjidForm/>
        </div>
        <div className='col-lg-6'>
            <MapContainer center={center} zoom={13} scrollWheelZoom={false} style={{ height: '400px' }}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <DraggableMarker />
            </MapContainer>
            {/* <AddMasjidForm/> */}
            {/* <MapBox masjids={placeholderMasjids}/> */}
        </div>
    </div>
   
  )
}

export default AddMasjid