import {  post, postForm  } from '../hooks/useHttp';

export const signIn = (data:any) => {
    return postForm(`/login`, data);
  };
  
  export const userLogIn = (data:any) => {
    return postForm(`/login`, data)
  };
  export const logout = () => {
    return post(`/logout`, {});
  };
  
  export const registerUser = (data:any) => {
    return postForm(`/register-api`, data);
  };

  export const changePassword = (data:any) => {
    return postForm(`/change-password`, data);
  };

  export const forgetPassword = (data:any) => {
    return postForm(`/forget-password`, data);
  };

  export const resetPassword = (data:any) => {
    return postForm(`/reset-password`, data);
  };