// import LoginCom from '../../components/Login/LoginCom';
import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import LoginComponent from '../../components/Login/LoginComponent.tsx';
import LoginSlider from '../../components/Login/LoginSlider';

function Login() {
 
  return (
    <div className="slick-and-login">
    <section className="login-section">
       <div className="container-fluid px-lg-5 px-md-4 px-3">
          <div className="row d-flex justify-content-center h-100">
             <div className="col-md-6 slider-colomn sticky-top">
                <LoginSlider/>
             </div>
             <div className="col-md-6 align-self-center">
                <GoogleOAuthProvider clientId="417032922464-ksm08898ns636fstul96pbt0n6qedmo8.apps.googleusercontent.com">
                   <LoginComponent/>
                 </GoogleOAuthProvider>
             </div>
          </div>
       </div>
    </section>
 </div>
  )
}

export default Login