import React  from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import LoginSlider from '../../components/Login/LoginSlider';
import RegisterComponent from '../../components/Register/RegisterComponent'
function Register() {
    
  return (
    <div className="slick-and-login">
    <section className="login-section">
       <div className="container-fluid px-lg-5 px-md-4 px-3">
          <div className="row d-flex justify-content-center h-100">
             <div className="col-md-6 slider-colomn sticky-top">
                <LoginSlider/>
             </div>
             <div className="col-md-6 align-self-center">
                <GoogleOAuthProvider clientId="417032922464-ksm08898ns636fstul96pbt0n6qedmo8.apps.googleusercontent.com">
                   <RegisterComponent/>
                 </GoogleOAuthProvider>
             </div>
          </div>
       </div>
    </section>
 </div>
   
  )
}

export default Register