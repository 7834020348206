import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { GoogleOAuthProvider } from "@react-oauth/google";

import LoginForm from "../../Login/LoginForm";
import RegisterForm from "../../Register/RegisterForm"
import SocialLogin from "../../Login/SocialLogin";
import SeparatorCom from "../../Login/SeparatorCom";

function LoginPopup(props: any) {
  const [showLoginForm, setShowLoginForm] = useState(true);

  const handelOnClose = () => {
    props.closePopup();
  };

  return (
    <Modal
      show={props.showModal}
      onHide={props.closePopup}
      id="modalPopup"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <div className="login-form pl-lg-4">
      {showLoginForm && <LoginForm origin="modal" onClose={handelOnClose} />}
      
      {showLoginForm && (
        <p className="mt-2" onClick={() => setShowLoginForm(false)}>Need an account?<span className="text-primary"> SingUp here</span></p>
      )}
      {/* 
      {showLoginForm && <LoginForm />} */}
      
      {!showLoginForm && <RegisterForm origin="modal"  onClose={handelOnClose}/>}
      {!showLoginForm && (
        <p className="mt-2" onClick={() => setShowLoginForm(true)}>Already Have an account?<span className="text-primary"> Login Here</span></p>
      )}
      <SeparatorCom />
      <GoogleOAuthProvider clientId="417032922464-ksm08898ns636fstul96pbt0n6qedmo8.apps.googleusercontent.com">
        <SocialLogin origin="modal"  onClose={handelOnClose}/>
      </GoogleOAuthProvider>
      </div>
    </Modal>
  );
}

export default LoginPopup;
