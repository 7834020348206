import React, { useState } from 'react';

// import About from '../../components/About/AboutCom';
import AboutCom from '../../components/About/AboutCom';
import '../../components/Masjid/MasjidCard/masjidcard.scss';
const NOMINATIM_BASE_URL = 'https://nominatim.openstreetmap.org/search?';
    const params = {
        q : '',
        format:'json',
        addressdetails:'addressdetails'
    };
function About() {
    
    const [search, setSearch] = useState('');
    const [listplace, setListplace] = useState([]);
    // console.log(search)
  return (
    <>
        <AboutCom></AboutCom>
    </>
    // <div >

    //     <div className="search-container">
    //         <input type="text" className="search-box" placeholder="Find Mosque Location..." value={search} onChange={(event)=>{
    //             setSearch(event.target.value);
    //         }}/>
    //         <button type="submit" className="search-button" onClick={()=>{
    //             const params = {
    //                 q : search,
    //                 format:'json',
    //                 addressdetails: 1,
    //                 polygon_geojson:0
    //             };
    //             const queryString = new URLSearchParams(params).toString();
    //             const requestOptions = {
    //                 method:'GET',
    //                 redirect:'follow'
    //             }
    //             fetch(`${NOMINATIM_BASE_URL}${queryString}`, requestOptions)
    //                 .then((response => response.json()))
    //                 .then((result) => {
    //                     console.log(result);
    //                     setListplace(result);
    //                 })
    //                 .catch((err) => console.log('err :', err));
    //         }}>Detect</button>
    //     </div>
    //     <ul>
    //         {
    //             listplace.map((item) => {
    //             return (<li key={item?.osm_id}>{item?.display_name}</li>)
    //         })
    //         }
    //     </ul>
    //     {/* <div className="drop-down">
    //         <h3>Here We Found Some Mosque</h3>
    //         <div className="dropdown">
    //             <button className="btn btn-secondary dropdown-toggle " id="secondary-btn" type="button" data-toggle="dropdown" aria-expanded="false">
    //                 Sort by
    //             </button>
    //             <div className="dropdown-menu">
    //                 <button className="dropdown-item" type="button">Action</button>
    //                 <button className="dropdown-item" type="button">Another action</button>
    //                 <button className="dropdown-item" type="button">Something else here</button>
    //             </div>
    //         </div>
    //     </div> */}
    // </div>
  )
}

export default About