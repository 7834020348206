import axios, { AxiosRequestConfig } from 'axios';

import Auth from '../Services/Auth';
import { environment } from '../../enviroment/enviroment';


const axiosConfig: AxiosRequestConfig = {
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
  },
  timeout: 60000,
  withCredentials: true,
  baseURL: environment.API,
};
const axiosClient = axios.create(axiosConfig);
const auth = Auth();

axiosClient.interceptors.request.use(
  (config) => {
    auth.getAuthToken();
    const token = auth.getAuthToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
//  Request interceptor
axiosClient.interceptors.response.use(
  (response) => {

    if ((!auth.getAuthToken()) && response.data.token) {
      localStorage.setItem('token', response?.data?.token);
    }
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status_code === 422 || error.response.status === 422) {
        return Promise.reject(error.response.data.errors);
      }
      if (error.response.status === 401 && auth.getAuthToken()) {
        auth.clearAllSession();
        window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);
export default axiosClient;
