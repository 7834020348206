import React from "react";

import ForgetPasswordCom from "../../components/ForgetPasswordComponent/ForgetPasswordCom";
import LoginSlider from '../../components/Login/LoginSlider'

function ForgetPassword(){
    return(
        <>
         <div className="slick-and-login">
            <section className="login-section">
               <div className="container-fluid px-lg-5 px-md-4 px-3">
                  <div className="row d-flex justify-content-center h-100">
                     <div className="col-md-6 slider-colomn sticky-top">
                        <LoginSlider/>
                     </div>
                     <div className="col-md-6 align-self-center">
                        <ForgetPasswordCom/>
                     </div>
                  </div>
               </div>
            </section>
         </div>
        </>
    )
}

export default ForgetPassword;