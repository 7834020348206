import React from "react";
import { NavLink } from "react-router-dom";
function LoginHeader(){
    return(
        <>
            <div className="logo">
                <NavLink className="" to="/">
                    Masjid Near Me
                </NavLink>
            </div>
            <h1>Log In to Masjid Near Me For More Information</h1>
        </>
    )
}

export default LoginHeader