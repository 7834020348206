import React,{useState} from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";

import { setUser } from "../../store/slices/authUserSlice";
import { setToken } from "../../store/slices/tokenSlice";
import "./loginform.css";
import { userLogIn } from "../../Services/clientService";
import Auth from "../../Services/Auth";

interface Props {
  origin?: string;
  onClose?: any;
}

function LoginForm(props: Props) {
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const auth = Auth();
  const Navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formData: any) => {
    try {
      const res = (await userLogIn(formData)) as any;
      if(res.success){
        dispatch(setUser(res.data.user));
        dispatch(setToken(res.data.token));
        auth.storeTokens(res.data.token);
        if (props.origin === "modal") {
          props.onClose();
        } else {
          setTimeout(() => {
            Navigate("/");
          }, 500);
        }
      }else{
        setErrorMessage(res.message)
      }
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="imgcontainer">
          <img
            src="https://img1.freepng.fr/20180331/ffe/kisspng-computer-icons-user-clip-art-user-5abf13dad7aed4.5909364715224718988835.jpg"
            alt="Avatar"
            className="avatar"
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            placeholder="E-mail Address"
            {...register("email", {
              required: "Email is required",
            })}
          />
          <span className="input-icon">
            <FontAwesomeIcon icon={faEnvelope} />
          </span>
        </div>
        {errors.email && typeof errors.email === "string" && (
          <p className="error">{errors.email}</p>
        )}
        <div className="form-group">
          <input
            type="password"
            placeholder="Password"
            {...register("password", {
              required: "Password is required",
            })}
          />
          <span className="input-icon">
            <FontAwesomeIcon icon={faLock} />
          </span>
        </div>
        {errors.password && typeof errors.password === "string" && (
          <p className="error">{errors.password}</p>
        )}
    {errorMessage && <p className="error">{errorMessage}</p>}
        <button className="login-btn">Login</button>
      </form>
    </>
  );
}

export default LoginForm;
