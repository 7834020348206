import React from 'react';
import './register.css';
import { useDispatch } from "react-redux";
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import { setUser } from "../../store/slices/authUserSlice";
import { setToken } from '../../store/slices/tokenSlice';
import { registerUser } from "../../Services/clientService";
import Auth from "../../Services/Auth";
interface Props {
  origin?: string;
  onClose?: any;
}

function RegisterComponent(props: Props){
    const dispatch = useDispatch();
    const auth = Auth();
    const Navigate = useNavigate();
    const {
      handleSubmit,
      register,
      formState: { errors },
      setError,
    } = useForm();

const onSubmit = async (formData: any) => {
    registerAction(formData);
};

const registerAction = async (formData: any) => {
    try {
      const res = (await registerUser(formData)) as any;
      const success = res.success;
      if(success){
        const token = res.data.token;
          auth.storeTokens(token);
          dispatch(setUser(res.data.user));
          dispatch(setToken(res.data.token));
          if (props.origin === "modal") {
            props.onClose();
          } else {
            setTimeout(() => {
              Navigate("/");
            }, 500);
          }
      }else {
        for (const field in res.message) {
          setError(field, {
            type: 'manual',
            message: res.message[field][0],
          });
        }
      }
     
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
    return(
        <form onSubmit={handleSubmit(onSubmit)}>
       
        <div className="imgcontainer">
          <img
              src="https://img1.freepng.fr/20180331/ffe/kisspng-computer-icons-user-clip-art-user-5abf13dad7aed4.5909364715224718988835.jpg"
              alt="Avatar" className="avatar"/>
        </div>
        <div className="form-group">
          <input type="text" placeholder="Username" {...register('username', {
              required: 'username is required' })}/>
          <span className="input-icon"><FontAwesomeIcon icon={faUser} /></span>
        </div>
          {errors.username && typeof errors.username.message === 'string' && <p className='error'>{errors.username.message}</p>}
        <div className="form-group">
          <input type="text" placeholder="Your name here" {...register('name', {
              required: 'name is required' })}/>
          <span className="input-icon"><FontAwesomeIcon icon={faUser} /></span>
        </div>
          {errors.name && typeof errors.name.message === 'string' && <p className='error'>{errors.name.message}</p>}
        <div className="form-group">
          <input type="email" placeholder="E-mail Address" {...register('email', {
              required: 'Email is required' })}/>
          <span className="input-icon"><FontAwesomeIcon icon={faEnvelope} /></span>
        </div>
       
          {errors.email && typeof errors.email.message === 'string' && <p className='error'>{errors.email.message}</p>}
        <div className="form-group">
          <input type="password" placeholder="Password" {...register('password', {
              required: 'password is required' })}/>
          <span className="input-icon"><FontAwesomeIcon icon={faLock} /></span>
        </div>
          {errors.password && typeof errors.password.message === 'string' && <p className='error'>{errors.password.message}</p>}
        <div className="form-group">
          <input type="password" placeholder="Confirm Password" {...register('c_password', {
              required: 'c_password is required' })}/>
          <span className="input-icon"><FontAwesomeIcon icon={faLock} /></span>
        </div>
          {errors.c_password && typeof errors.c_password.message === 'string' && <p className='error'>{errors.c_password.message}</p>}

        <button className="login-btn">Submit</button>
    </form>
    )
}

export default RegisterComponent;