import React from "react";

function SeparatorCom(){
    return(
        <div className="seperator">
          <b>or</b>
        </div>
    )
}

export default SeparatorCom;