import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import LoginPopup from "../../../Masjid/MasjidCard/LoginPopup";
import EditPopup from "../../../Popup/EditPopup";
import AddPhone from "../../../Popup/AddPhoneNumberPopup/AddPhone";
import './editmasjidbutton.scss';

// Define the props interface for the EditMasjidButton component
interface EditMasjidButtonProps {
  masjid: any;
  onEdit: (masjid: any) => void;
}

function EditMasjidButton(props: EditMasjidButtonProps) {
  const [showLoginPopup, setshowLoginPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showToaster, setShowToaster] = useState(false);
  const [showPhonePopup, setShowPhonePopup] = useState(false);
  const notify = (message: string) => {
    setShowToaster(true);
    toast.success(message);
  };
  const user = useSelector((state: any) => {
    return state.user;
  });
  const token = useSelector((state: any) => {
    return state.token;
  });

  const handleShow = () => {
    if (!token) {
      setshowLoginPopup(true);
    } else if (token && user && user.roles.includes("masjid_admin")) {
      setShowModal(true);
    } else if (token && user && !user.phone_number) {
      console.log("chekc phone");
      setShowPhonePopup(true);
    } else {
      setShowModal(true);
    }
  };
  const handleClose = () => setShowModal(false);
  return (
    <>
      <Button variant="link" className="edit-button" onClick={handleShow}>
        <FontAwesomeIcon icon={faPenToSquare as IconProp} />
      </Button>
      {showModal && (
        <EditPopup
          masjidData={props.masjid}
          closePopup={handleClose}
          showModal={showModal}
          toaster={notify}
          setMasjidTime={(masjid) => props.onEdit(masjid)}
        />
      )}
      {showToaster && <ToastContainer />}
      {showLoginPopup && (
        <LoginPopup
          closePopup={() => setshowLoginPopup(false)}
          showModal={showLoginPopup}
        />
      )}

      {showPhonePopup && (
        <AddPhone
          closePopup={() => setShowPhonePopup(false)}
          showPhoneModal={showPhonePopup}
        />
      )}
    </>
  );
}

export default EditMasjidButton;
