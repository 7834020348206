import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Skeleton } from '@mui/material';
import './FindMasjid.scss';

import MosqueCard from '../../components/Masjid/MasjidCard/MosqueCard';
// import MasjidCard from '../../components/Masjid/MasjidCard/MasjidCard.tsx';
import MapBox from '../../components/Masjid/MapCard/MapBox'
import { getMajids } from "../../Services/masjidService";
import SearchInput from '../../components/Input/SearchInput';
import DetectButton from '../../components/DetectButton/DetectButton';
import PerPage from '../../components/Pagination/PerPage';
import MapView from '../../components/MapViewToggleButton/MapView.tsx';


function FindMasjid() {

    const [hoveredMasjid, setHoveredMasjid] = useState(null);

    const Navigate = useNavigate();
    const [userLocation, setUserLocation] = useState([28.65068, 77.23342]);
    const [masjids, setMasjids] = useState([]);
    const [activeMasjid, setActiveMasjid] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [paginateResults, setpaginateResults] = useState();
    const [view, setView] = useState('Map');
    const [mapContainerStyle, setMapContainerStyle] = useState({
        zIndex: window.innerWidth >= 992 ? 1 : -1 // Set default zIndex based on screen size
    });


    const loadMosque = useCallback(async () => {
        const res = await getMajids(currentPage, userLocation);
        setMasjids(res.results);
        if (res.results.length > 0) {
            setActiveMasjid(res.results[0]);
        }
        setTotalPages(res.pagination.total_pages);
        setpaginateResults(res.pagination);
    }, [currentPage, userLocation]);

    const { isFetching, isLoading } = useQuery({
        queryKey: ['get-mosque', currentPage, userLocation],
        enabled: true,
        refetchOnWindowFocus: false,
        queryFn: loadMosque,
    });

    // const handleView = () => {
    //     if (view === 'Map') {
    //         // Switch to list view
    //         setView('List');
    //         setMapContainerStyle({ zIndex: 0 });
    //     } else {
    //         // Switch to map view
    //         setView('Map');
    //         setMapContainerStyle({ zIndex: window.innerWidth >= 992 ? 1 : -1 });
    //     }
    // };
    const handleUserLocation = (location) => {
        setMasjids([]);
        setUserLocation([location.latitude, location.longitude]);
        setCurrentPage(1);
    }


    const handleUserCurrentLocation = (location) => {
        setMasjids([]);
        setUserLocation([location.latitude, location.longitude]);
        setCurrentPage(1);
    }

    return (
        <div className='container-fluid mosque_card_container'>
            <div className='row'>
                <div className='col-lg-6 bg-white'>
                    <div>
                        <div className="search-container d-flex justify-content-between align-items-center px-2">
                            <SearchInput
                                setUserLocation={handleUserLocation}
                            />
                            <DetectButton
                                setUserLocation={handleUserCurrentLocation}
                            />
                        </div>
                    </div>
                    <div className="find-masjid-mosque mosques">
                        {masjids && !isFetching && masjids.length > 0 && masjids.map((masjid) => (
                            <MosqueCard
                                key={masjid.id}
                                masjid={masjid}
                                onHover={setHoveredMasjid}
                            />
                        ))}

                        {isFetching &&
                            <div className='mt-2'>
                                {/* Example of a single-line text skeleton */}
                                <Skeleton variant="text" width={200} />

                                {/* Example of a rectangular skeleton */}
                                <Skeleton variant="rectangular" width="100%" height={150} />

                                <Skeleton variant="text" width={200} />

                                {/* Example of a rectangular skeleton */}
                                <Skeleton variant="rectangular" width="100%" height={150} />
                                <Skeleton variant="text" width={200} />

                                {/* Example of a rectangular skeleton */}
                                <Skeleton variant="rectangular" width="100%" height={150} />
                                <Skeleton variant="text" width={200} />
                                <Skeleton variant="rectangular" width="100%" height={150} />
                                {/* Example of a circular skeleton */}
                            </div>}
                    </div>
                    <div className='row'>
                        {paginateResults && <PerPage
                            setCurrentPage={setCurrentPage}
                            paginateResults={paginateResults}
                            currentPage={currentPage}
                            totalPages={totalPages}
                        />}

                        {/* <div className='button-container'>
                            <Button onClick={handleView}>{view}</Button>
                        </div> */}
                        <MapView
                            setMapContainerStyle={setMapContainerStyle}
                        />
                        {/* <div className="mapViewBtn">
                            <button className="text-light view-btn btn d-block" onClick={handleView}
                            >{view}
                            </button>
                        </div> */}
                    </div>

                </div>
                <div className='col-lg-6 d-lg-block map_container' style={mapContainerStyle}>
                    <MapBox
                        masjids={isFetching ? [] : masjids}
                        hoveredMasjid={hoveredMasjid}
                        userLocation={userLocation ? userLocation : [28.65068, 77.23342]}
                        setUserLocation={handleUserCurrentLocation}
                    />
                </div>

            </div>


        </div>
    )
}

export default FindMasjid