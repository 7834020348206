const Auth = {

    logout: () => {
        // Implement your logout logic here
        localStorage.removeItem('token');
    },

    isAuthenticated: () => {
        // Check if the user is authenticated (e.g., by checking if a token exists)
        return !!localStorage.getItem('token');
    }
};

export default Auth;
