import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from 'react-query';

import createStoreAndPersistor from './app/store/index.jsx';
import App from './app/App';
import reportWebVitals from './reportWebVitals';

const { store, persistor } = createStoreAndPersistor();
const root = ReactDOM.createRoot(document.getElementById('root'));
// store.subscribe(() => {
//   console.log(store.getState());
// });
const queryClient = new QueryClient();
root.render(
  <Router>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
