import React, { useState, useCallback } from "react";
import { useQuery } from "react-query";
import { Skeleton } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import "./FavouriteMasjid.scss";

import { getFavMasjids } from "../../Services/GetFavMasjid";
// import MasjidCard from ".../../components/PhoneVersion/MasjidCard";
// import MasjidCard from "../../components/Masjid/MasjidCard/MasjidCard"; // eslint-disable-line @typescript-eslint/no-var-requires
import MosqueCard from "../../components/Masjid/MasjidCard/MosqueCard";


function FavouriteMasjid() {
  const [masjids, setMasjids] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  
  const loadMosque = useCallback(async () => {
    try{
      
    const res = (await getFavMasjids(currentPage)) as any;
    const { results, pagination } = res.data;
    setMasjids((prevMasjids: any) => [...prevMasjids, ...results]);
    setTotalPages(pagination.total_pages);
    }catch(error:any){
      setErrorMessage(error.response.data.message);
    }
  }, [currentPage]);

  const { isFetching } = useQuery({
    queryKey: ["get-fav-mosque", currentPage],
    enabled: true,
    refetchOnWindowFocus: false,
    queryFn: loadMosque,
  });

  const fetchMoreData = () => {
    setCurrentPage((prevpage) => prevpage + 1); // Increment page number
  };

  const setHoveredMasjid = (masjid: any) => {
    // console.log(masjid);
    console.log(isFetching);
  };

  return (
    <>
    <header>
        <h1>Favourite Masjids</h1>
    </header>
    <div className="container-fluid fav-masjid-container-fluid">
      <div className="row">
        <div className="col-12 bg-white">
          <div className="fav-masjid-container">
            {errorMessage && <p className="error mt-5">{errorMessage}</p>}
            <div className="mosques">

              <InfiniteScroll
                dataLength={masjids.length}
                next={fetchMoreData}
                hasMore={currentPage < totalPages} // Check if there are more pages to load
                loader={
                  <div className="fav-masjid-loader">
                    {/* Skeleton loading indicators */}
                    <Skeleton variant="text" width={200} />
                    <Skeleton variant="rectangular" width="100%" height={150} />
                    {/* Repeat as needed */}
                  </div>
                }
                pullDownToRefreshThreshold={100}
                scrollableTarget="scrollableDiv"
              >
                {masjids.map((masjid: any) => (
                  <MosqueCard
                    key={masjid.id}
                    masjid={masjid}
                    onHover={setHoveredMasjid}
                  />
                ))}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default FavouriteMasjid;
