import React from "react";
import { useState } from "react";
import "./masjidcard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonWalking } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

import FavToggleButton from "../Button/FavToggleButton";
import EditMasjidButton from "../Button/EditMasjid/EditMasjidButton";

import Fajr from "./CardAssets/prayicon/fajr.png";
import Zuhr from "./CardAssets/prayicon/zuhr.png";
import Juma from "./CardAssets/prayicon/juma.png";
import Asar from "./CardAssets/prayicon/asar.png";
import Maghrib from "./CardAssets/prayicon/magrib.png";
import Ishaa from "./CardAssets/prayicon/ishaa.png";

function MosqueCard(props: any) {
  const [masjidTime, setMasjidTime] = useState(props.masjid);

  if (!props.masjid) return <></>;

  return (
    <>
      <div
        className={`mosque-details grey-bg ${
          props.smallerVersion ? "smaller" : ""
        }`}
        key={props.masjid.id}
        onMouseEnter={() => props.onHover(props.masjid)}
        onMouseLeave={() => props.onHover(null)}
      >
        <div className="mosque-card-top d-flex align-items-start justify-content-between">
          {/* <div className="mosque-img">
            <img src={tstimg} />


          </div> */}
          <div className="mosque-adress">
            <h3 className="mosque-name">
              {props.masjid.name}
              {/* <a target='_blank' href={`https://www.google.com/maps/dir/?api=1&destination=${props.masjid.latitude},${props.masjid.longitude}`}><FontAwesomeIcon icon={faPersonWalking } style={{  fontSize: '24px' }} /><span> Get Direction</span></a> */}
            </h3>

            <div className="adress">
              {/* <i className="fa-solid fa-location-dot" style={{ color: "indianred" }}></i> */}
              <div className="adress-pointer">{props.masjid.address}</div>
            </div>
            <div className="distance">
              {/* <i className="fa-solid fa-people-arrows" style={{ color: "green" }}></i> */}
              {props.masjid.distance_to_masjid ? (
                <div>
                  <strong>Distance:</strong>
                  {props.masjid.distance_to_masjid}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="masjid-button-conatiner d-flex align-items-start">
            <FavToggleButton masjidId={props.masjid.id} />

            <EditMasjidButton
              masjid={props.masjid}
              onEdit={(masjid) => setMasjidTime(masjid)}
            />

            {/* <EditMasjidButton masjid={props.masjid} /> */}
            <Button
              className="direction-button"
              variant="link"
              target="_blank"
              href={`https://www.google.com/maps/dir/?api=1&destination=${props.masjid.latitude},${props.masjid.longitude}`}
            >
              <FontAwesomeIcon icon={faPersonWalking} />
            </Button>
          </div>
        </div>
        <div className="prayer-times-container">
          <div className="prayer-time-item" style={{ background: "#00401A" }}>
            <div className="prayer-label">Fajr</div>
            <img
              className="namaz-icon"
              src={Fajr}
              alt="Fajr"
              style={{ width: "20px" }}
            />
            <div className="prayer-time">{masjidTime.fajr} AM</div>
          </div>
          <div
            className="prayer-time-item juma"
            style={{ background: "#E30A17" }}
          >
            <div className="prayer-label">Juma</div>
            <img
              className="namaz-icon"
              src={Juma}
              alt="Fajr"
              style={{ width: "20px" }}
            />
            <div className="prayer-time">{masjidTime.juma}</div>
          </div>
          <div className="prayer-time-item" style={{ background: "#00401A" }}>
            <div className="prayer-label">Dhuhr</div>
            <img
              className="namaz-icon"
              src={Zuhr}
              alt="Fajr"
              style={{ width: "20px" }}
            />
            <div className="prayer-time">{masjidTime.zohar} PM</div>
          </div>
          <div className="prayer-time-item" style={{ background: "#00401A" }}>
            <div className="prayer-label">Asr</div>
            <img
              className="namaz-icon"
              src={Asar}
              alt="Fajr"
              style={{ width: "20px" }}
            />
            <div className="prayer-time">{masjidTime.asar} PM</div>
          </div>
          <div className="prayer-time-item" style={{ background: "#00401A" }}>
            <div className="prayer-label">Maghrib</div>
            <img
              className="namaz-icon"
              src={Maghrib}
              alt="Fajr"
              style={{ width: "20px" }}
            />
            <div className="prayer-time">{masjidTime.maghrib} PM</div>
          </div>
          <div className="prayer-time-item" style={{ background: "#00401A" }}>
            <div className="prayer-label">Isha</div>
            <img
              className="namaz-icon"
              src={Ishaa}
              alt="Fajr"
              style={{ width: "20px" }}
            />
            <div className="prayer-time">{masjidTime.isha} PM</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MosqueCard;
