import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";

import LoginPopup from "../../../components/Masjid/MasjidCard/LoginPopup";
import { addFavMasjid } from "../../../Services/FavMasjidService";
import { setUser } from "../../../store/slices/authUserSlice";
import "./favtogglebutton.scss";

function FavToggleButton({ masjidId }: any) {
  const [showLoginPopup, setshowLoginPopup] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state: any) => {
    return state.user;
  });
  const token = useSelector((state: any) => {
    return state.token;
  });
  // console.log('user', user);
  const isMasjidFav = () => {
    return token && user && user.favoriteMasjids.includes(masjidId)
      ? true
      : false;
  };

  const { isLoading, refetch: favMasjidHandler } = useQuery({
    queryKey: ["fav-masjid", masjidId],
    enabled: false,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      await addFavMasjid(masjidId, isMasjidFav() ? "remove" : "add");
    },
    onSuccess: (data: any) => {
      // Create a copy of the user object
      const updatedUser = { ...user };
      if (isMasjidFav()) {
        // Filter out the removed favorite masjid
        updatedUser.favoriteMasjids = updatedUser.favoriteMasjids.filter(
          (id: any) => id !== masjidId
        );
      } else {
        // Add the new favorite masjid
        updatedUser.favoriteMasjids = [
          ...updatedUser.favoriteMasjids,
          masjidId,
        ];
      }
      console.log(updatedUser);
      // Dispatch the action with the updated user object
      dispatch(setUser(updatedUser));
    },
  });

  const onClickHandler = () => {
    console.log("clck");
    if (!token) {
      setshowLoginPopup(true);
    } else {
      favMasjidHandler();
    }
  };

  return (
    <>
      <div className="fav_btn">
        <Button
          className={`fav-button ${isMasjidFav() ? 'active': ''}`}
          variant="link"
          onClick={onClickHandler}
          disabled={isLoading}
        >
          {isMasjidFav() ? (
            <FontAwesomeIcon icon={faHeartSolid as IconProp} />
          ) : (
            <FontAwesomeIcon icon={faHeart as IconProp} />
          )}
        </Button>
      </div>
      {showLoginPopup && (
        <LoginPopup
          closePopup={() => setshowLoginPopup(false)}
          showModal={showLoginPopup}
        />
      )}
    </>
  );
}

export default FavToggleButton;
