import { post } from "../hooks/useHttp";
export const addFavMasjid = (masjidId: any, action: string) => {
  console.log("editAction");
  const requestData = { masjidId: masjidId, action: action };
  return post(`/fav-masjid`, requestData);
};

export const getUpdateData = (masjidId: any) => {
  const requestData = { masjidId: masjidId };
  return post(`/getUpdatedData`, requestData);
};
