import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import '../../../../../node_modules/leaflet/dist/leaflet.css';
import Card from 'react-bootstrap/Card';
import { Icon } from 'leaflet';
import L from 'leaflet';

// import MasjidCard from '../MasjidCard/MasjidCard.js';
import MosqueCard from '../MasjidCard/MosqueCard';

import mapIcon from './images/mapIcon.png';
import defIcon from './images/iconDef.png';
import userLocationIcon from './images/userLocationIcon.png';



function MapBox({ masjids, hoveredMasjid, userLocation, setUserLocation }) {
    const mapRef = useRef(null);
    const hoveredMasjidRef = useRef(null);
    hoveredMasjidRef.current = hoveredMasjid;

    useEffect(() => {
        if (masjids.length > 0) {
            if (mapRef.current) {
                const bounds = L.latLngBounds(masjids.map((point) => [point.latitude, point.longitude]));
                mapRef.current.fitBounds(bounds);
            }
        }
    }, [masjids])

    const customIcon = new Icon({
        iconUrl: defIcon,
        iconSize: [30, 30]
    })

    const hoverdIcon = new Icon({
        iconUrl: mapIcon,
        iconSize: [30, 30]
    });

    const userIcon = new Icon({
        iconUrl: userLocationIcon,
        iconSize: [40, 40]
    });

    const clusterIconStyle = {
        backgroundColor: 'green',
        borderRadius: '50%',
        height: '30px',
        width: '30px',
        textAlign: 'center',
        paddingTop: '5px',
        color: 'white',
    };


    const isMasjidInCluster = (cluster, point) => {
        return cluster.getAllChildMarkers().some((marker) => {
            const markerLatLng = marker.getLatLng();
            return markerLatLng.lat == point.latitude && markerLatLng.lng == point.longitude;
        });
    };

    // Custom icon create function for MarkerClusterGroup
    const clusterIconCreateFunction = (cluster) => {
        // Check if the cluster contains a specific point
        let containsSpecificPoint = false;
        if (hoveredMasjidRef.current) {
            containsSpecificPoint = isMasjidInCluster(cluster, hoveredMasjidRef.current) ? true : false;
        }

        // Define the cluster icon based on the condition
        return L.divIcon({
            html: `<div  style="background-color: ${containsSpecificPoint ? 'red' : 'green'};border-radius:50%;height: 30px; width: 30px; text-align: center; padding-top: 5px; color: white; ">${cluster.getChildCount()}</div>`,
            className: 'custom-cluster-icon',
            iconSize: [40, 40],
        });
    };
    const onMarkerDragEnd = (coord) => {
        const latitude = coord.lat;
        const longitude = coord.lng;
        setUserLocation({ latitude, longitude });
    };

    const setHoveredMasjid = (masjid) => {
        console.log(masjid)
    }

    return (
        <>
            {/* <h1>{userLocation.latitude ? userLocation.latitude : 'nothig'}</h1> */}
            <MapContainer ref={mapRef} style={{ height: 'calc(100vh - 80px)' }}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {/* <TileLayer
                    attribution='Tiles &copy; Esri'
                    url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                /> */}
                {userLocation && (
                    <Marker position={userLocation} icon={userIcon} draggable={true}
                        eventHandlers={{
                            dragend: (e) => onMarkerDragEnd(e.target.getLatLng())
                        }}>
                    </Marker>
                )}

                <MarkerClusterGroup
                    iconCreateFunction={clusterIconCreateFunction}
                    spiderLegPolylineOptions={{ weight: 0 }}
                >

                    {masjids.map((masjid) => (
                        <Marker key={masjid.id} position={[masjid.latitude, masjid.longitude]}
                            icon={(hoveredMasjid && hoveredMasjid.id === masjid.id) ? hoverdIcon : customIcon}
                        >
                            <Popup>
                                <MosqueCard
                                    key={masjid.id}
                                    masjid={masjid}
                                    onHover={setHoveredMasjid}
                                    smallerVersion={true}
                                />
                            </Popup>
                        </Marker>
                    ))}
                </MarkerClusterGroup>
            </MapContainer>
        </>
    )
}

export default MapBox