import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

import './forgetpassword.scss';
// import LoginHeader from "../Login/LoginHeader";
import { forgetPassword } from "../../Services/clientService";

import flyingPlane from './images/flying-plane.png';

function ForgetPasswordCom(){ 
  const [message, setMessage] = useState("");
  const [sentEmail, setSentEmail] = useState(true);
  const [submittedEmail, setSubmittedEmail] = useState("");
    const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formData: any) => {
    try {
      const res = await forgetPassword(formData) as any;
      console.log(res);
      if (res.status) {
        setSentEmail(false);
        setMessage(res.message);
        setSubmittedEmail(formData.email);
      } else {
        setMessage("Reset Link sent to your email");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setMessage("An error occurred.");
    }
  };
    return(
        <>
        {sentEmail && (
        <div className="login-form forgetPassword pl-lg-4">
          <div className="form-inputs_header">
            <h2 className="form-input_title">Forgot your password?</h2>
            <p>Enter your Email to recieve a password resent email.</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
              <div className="imgcontainer">
                <img
                  src="https://img1.freepng.fr/20180331/ffe/kisspng-computer-icons-user-clip-art-user-5abf13dad7aed4.5909364715224718988835.jpg"
                  alt="Avatar"
                  className="avatar"
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="E-mail Address"
                  {...register("email", {
                    required: "Email is required",
                  })}
                />
                <span className="input-icon">
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
              </div>
              {errors.email && typeof errors.email === "string" && (
                <p className="error">{errors.email}</p>
              )}
              
              <NavLink className="nav-link text-primary m-3 text-center" to="/login">I remember my login details</NavLink>
              <button className="login-btn">Submit</button>
              {message && <p className="mt-2"><span className="forget-msg">{message}</span></p>}
          </form>
          
        </div>
        )}
        {
          !sentEmail && (
            
          <div className="login-form forgetPassword pl-lg-4">
            <div className="form-inputs_header">
            <div className="flying-plane text-center">
              <img src={flyingPlane} alt="" />
            </div>
              <h2 className="form-input_title">You've got mail!</h2>
            </div>
            
            <div className="text-center" id="error-msg">We've sent reset link to your email: <strong>{submittedEmail}</strong></div>
            <NavLink className="nav-link text-primary m-3 text-center" to="/login">I remember my login details</NavLink>
          </div>
          )
        }
        </>
    )
}

export default ForgetPasswordCom;