import React from "react";
import { NavLink } from 'react-router-dom';
 
function LoginHereCom(){
    return(
        <>
            <p>need an account? <NavLink className="" to="/login">Login here</NavLink> </p>
        </>
    )
}

export default LoginHereCom