import React from "react";

import UpdatePassword from "../../components/UpdatePassword";

function ChangePassword(){
    return(
        <>
        <UpdatePassword/>
        </>
    )
}
export default ChangePassword;