import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

import { clearUser } from "../../store/slices/authUserSlice";
import { clearToken } from "../../store/slices/tokenSlice";

const LogoutModal = (props: any) => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    props.setShowLogoutPopup(false);
  };
  const handleLogout = () => {
    localStorage.clear();
    dispatch(clearUser());
    dispatch(clearToken());
    props.setShowLogoutPopup(false);
    setShow(false);
    setTimeout(() => {
      Navigate('/');
    }, 500);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>

        <Modal.Body>Are you sure you want to logout?</Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LogoutModal;
