import React, { useEffect, useState } from 'react';
// import axios from 'axios';
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';

// import Auth from '../../Services/Auth';
import AuthOld from '../../Services/AuthOld';

function AddMasjidForm() {
  useEffect(() => {
    // Initialize the Autocomplete
    const input = document.getElementById('location');
    const autocomplete = new window.google.maps.places.Autocomplete(input, {
      types: ['geocode'], // You can specify the types of predictions you want (e.g., cities, addresses, etc.)
    });

    // Listen for place selection
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      // Do something with the selected place (e.g., store it in state or use it as needed)
      console.log(place);
    });
  }, []); // The empty dependency array ensures that this runs once when the component mounts
  const Navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm();

  const [successMessage, setSuccessMessage] = useState('');
  // const [errorMessage, setErrorMessage] = useState('');
  // const [validationErrors, setValidationErrors] = useState({});

  const onSubmit = async (formData) => {
    
    const masjidAdded = await AuthOld.addmasjid(formData);
    console.log('check');
    console.log(masjidAdded);
    
  };

  return (
    <div className=''>
      <div className='registration-form' style={{minWidth:"280px"}}>
        <form onSubmit={handleSubmit(onSubmit)}>
        <label>Masjid Name</label>
        <input
            type='text'
            name='name'
            placeholder='Masjid Name'
            {...register('name', { required: 'Masjid Name is required' })}
          />
          {errors.name && <p className='error'>{errors.name.message}</p>}

          <label>City</label>
        <input
            type='text'
            name='city'
            placeholder='City'
            {...register('city', { required: 'City is required' })}
          />
        {errors.city && <p className='error'>{errors.city.message}</p>}

        <label>Location</label>
        <input
            type='text'
            name='location'
            id="location"
            placeholder='Add Masjid Location'
            {...register('location', { required: 'Location is required' })}
          />
        {errors.location && <p className='error'>{errors.location.message}</p>}

        <label>Fajr</label>
        <input
            type='time'
            name='fajr'
            placeholder='Fajr'
            {...register('fajr', { required: 'Namaz time is required' })}
          />
        {errors.fajr && <p className='error'>{errors.fajr.message}</p>}

        <label>Dhuhr</label>
        <input
            type='time'
            name='zuhar'
            placeholder='Dhuhr '
            {...register('zuhar', { required: 'Namaz time is required' })}
          />
        {errors.zuhar && <p className='error'>{errors.zuhar.message}</p>}
        <label>Asr</label>
        <input
            type='time'
            name='asar'
            placeholder='Asr'
            {...register('asar', { required: 'Namaz time is required' })}
          />
        {errors.asar && <p className='error'>{errors.asar.message}</p>}
        {/* {validationErrors.username && (
            <p className="error">{validationErrors.username[0]}</p>
          )} */}
          <label>Maghrib</label>
          <input
            type="time"
            name='maghrib'
            placeholder='Maghrib'
            {...register('maghrib', { required: 'Namaz time is required' })}
          />
          {errors.maghrib && <p className='error'>{errors.maghrib.message}</p>}
          <label>Isha</label>
          <input
            type='time'
            name='isha'
            placeholder='Isha'
            {...register('isha',  { required: 'Namaz time is required' })}
          />
          {errors.isha && <p className='error'>{errors.isha.message}</p>}
          <label>Juma</label>
          <input
            type='time'
            name='juma'
            placeholder='Juma'
            {...register('juma', {required: 'Namaz time is required' })}
          />
          {errors.juma && <p className='error'>{errors.juma.message}</p>}
          <label>Tarabhi</label>
          <input
            type='time'
            name='tarabhi'
            placeholder='Tarabhi'
            {...register('tarabhi', { required: 'tarabhi is required' })}
          />
          {errors.c_password && (
            <p className='error'>{errors.c_password.message}</p>
          )}

          <button type='submit'>Add Masjid</button>
          {successMessage && <p className="error">{successMessage}</p>}
        </form>
      </div>
    </div>
  );
}

export default AddMasjidForm;
