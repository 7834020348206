import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector} from "react-redux";

import { addPhoneNo } from "../../../Services/AddPhoneNoService";
import { setUser } from "../../../store/slices/authUserSlice";

function AddPhone(props:any){
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => {
    return state.user;
  });

    const { register, handleSubmit, formState: { errors } } = useForm();
    const handelOnClose = () => {
        props.closePopup();
      };
    const onSubmit = async (formData: any) => {
      try {
        const res = (await addPhoneNo(formData)) as any;
        console.log(res)
        dispatch(setUser({...userData, phone_number: formData.phone_number}));
        props.closePopup();
      } catch (error) {
        console.error("Error fetching data:", error);
      }

    };
    return(
        <Modal
            show={props.showPhoneModal}
            onHide={handelOnClose}
            id="modalPopup"
        centered
      >
        <Modal.Header closeButton>
            <Modal.Title>Enter Your Phone Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="col-12 mb-3">
            <Form.Group controlId="formPhone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your phone number"
                {...register("phone_number", { required: true })}
              />
              {errors.phone && <span>This field is required</span>}
            </Form.Group>
        </div>
          <Button variant="success" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
      </Modal>
    )
}

export default AddPhone;