import axiosClient from '../config/axiosClient';

// export const get = (url: string, config = {}) => {
//   return new Promise((resolve, reject) => {
//     axiosClient
//       .get(url, config)
//       .then((response) => {
//         resolve(response.data);
//       })
//       .catch((error) => {
//         resolve({ data: null, error });
//       });
//   });
// };

export const get = (url: string, config = {}) => {
  return new Promise((resolve, reject) => {
    axiosClient
      .get(url, config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response); // or resolve({ data: null, error: response });
        }
      })
      .catch((error) => {
        console.error('Error in HTTP request:', error);
        reject(error);
      });
  });
};


export const post = async (
  url: string,
  formData: object | null,
  options: any = {}
): Promise<object> => {
  return await axiosClient.post(url, { ...formData }, options);
};

export const postForm = (url: string, config = {}) => {
  return new Promise((resolve, reject) => {
    axiosClient
      .post(url, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve({ data: null, error });
      });
  });
};

export const put = async (
  url: string,
  formData: object | null,
  options: any = {}
): Promise<object> => {
  return await axiosClient.put(url, { ...formData }, options);
};

export const deleteData = async (url: string): Promise<object> => {
  return await axiosClient.delete(url);
};
