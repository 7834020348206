import React,{useState} from "react";
import './mapview.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faList , faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
// import { faHeart } from "@fortawesome/free-regular-svg-icons";

function MapView(props:any){
    const [view, setView] = useState('Map');
    const handleView = () => {
        if (view === 'Map') {
            // Switch to list view
            setView('List');
            props.setMapContainerStyle({ zIndex: 0 });
        } else {
            // Switch to map view
            setView('Map');
            props.setMapContainerStyle({ zIndex: window.innerWidth >= 992 ? 1 : -1 });
        }
    };
    return(
        <div className="mapViewBtn">
            <button className="text-light view-btn btn d-block" onClick={handleView}
            >{view == 'Map' &&  <FontAwesomeIcon icon={faMapLocationDot as IconProp} />}
            {view == 'List' &&  <FontAwesomeIcon icon={faList as IconProp} />}
            </button>
        </div>
    )
}

export default MapView;