import React from "react";

import AccountDetails from "../../components/AccountDetails";

function ResetProfile(){
    return(
        <>
            <AccountDetails/>
        </>
    )
}

export default ResetProfile;