import React from "react";
 
function AccountDetails(){
    return(
        <>
        <header>
                <h1>Reset Profile</h1>
                <h6>Manage your Account settings</h6>
        </header>
        </>
    )
}

export default AccountDetails;