import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faMultiply, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import './header.scss';
// import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from 'react-router-dom';

import LogoutModal from "../Logout/LogoutModal"
function Header() {

  const location = useLocation();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);

  const user = useSelector((state) => {
    return state.user;
  });

  const isUserLoggedIn = () => {
    return user && user.id !== '' ? true : false;
  }

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const handleLogout = () => {
    console.log('logout call');
    setShowLogoutPopup(true); // Show logout popup
    // console.log(showLogoutPopup);
    setSidebarOpen(false);
  };

  const token = localStorage.getItem("token");
  const excludedPaths = ['/login', '/register', '/forget-password' , '/reset-password'];

  if (excludedPaths.includes(location.pathname)) {
    return null; // Don't render the Navbar
  }

  // console.log(isUserLoggedIn())  

  return (
    <div className='header-menu'>
      <nav>
        <input type="checkbox" id="check" checked={isSidebarOpen}
          onChange={() => setSidebarOpen(!isSidebarOpen)} />

        <label className="logo">Masjid Near Me</label>
        <label htmlFor="check" className="checkbtn">
          {isSidebarOpen ?
            <FontAwesomeIcon icon={faMultiply} /> : <FontAwesomeIcon icon={faBars} />}
        </label>
        <ul>
          <li><NavLink className="nav-link" to="/" onClick={closeSidebar} >Home</NavLink></li>
          <li><NavLink className="nav-link" to="/about" onClick={closeSidebar} >About</NavLink></li>
          {/* <li><NavLink className="nav-link" to="/contact" onClick={closeSidebar} >Contact</NavLink></li> */}
          {!isUserLoggedIn() ? (
            <>
              {/* <li><NavLink className="nav-link" to="/forget-password" onClick={closeSidebar}>
                <b>Forget Password</b>
              </NavLink>
              </li> */}
              <li><NavLink className="nav-link signIn" to="/login" onClick={closeSidebar}>
                <b>Join / Sign In</b>
              </NavLink>
              </li>
              
            </>
          ) : (
            <>
              <li className="dropdown">
                <button className="dropbtn">{user.name} <span className='icon'><FontAwesomeIcon  icon={faChevronDown} /></span></button>
                <div className="dropdown-content">
                  <NavLink className="settings-dropdown" to="/favourite-masjids" >Favourite Masjids ❤</NavLink>
                  <NavLink className="settings-dropdown" to="/edit-profile" >Edit Profile</NavLink>
                  <NavLink className="settings-dropdown" to="/change-password" >Change Password</NavLink>
                  <NavLink className="settings-dropdown" onClick={handleLogout}>Logout</NavLink>
                </div>
              </li>
              <li className="mobile-item">
                <NavLink className="nav-link" to="/favourite-masjids" onClick={closeSidebar} >Favourite Masjids</NavLink>
              </li>
              <li className="mobile-item">
                <NavLink className="nav-link" to="/edit-profile" onClick={closeSidebar}>Edit Profile</NavLink>
              </li>
              <li className="mobile-item">
                <NavLink className="nav-link" to="/change-password" onClick={closeSidebar}>Change Password</NavLink>
              </li>
              <li className="mobile-item">
                <NavLink className="nav-link" onClick={handleLogout}>Logout</NavLink>
              </li>
            </>
            /* <button className='btn btn-success text-white text-uppercase' onClick={handleLogout}>
          <b>Logout</b>
        </button> */

          )}
          {
            showLogoutPopup && (
              <LogoutModal
                setShowLogoutPopup={setShowLogoutPopup}
              />
            )
          }
        </ul>
      </nav>
    </div>
  )
}

export default Header