// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.
interface Environment {
    production: boolean;
    preProd?: boolean;
    API: string;
  }
  export const environment: Environment = {
    production: false,
    API: 'https://admin.meandmyteam.org/api',
  };
  