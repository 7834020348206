import React from 'react';
import { useState } from 'react';
import './phonemasjidcard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonWalking } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

import FavToggleButton from '../../Masjid/Button/FavToggleButton';
import EditMasjidButton from '../../Masjid/Button/EditMasjid/EditMasjidButton';
import 'react-toastify/dist/ReactToastify.css';

function PhoneMasjidCard(props:any) {

  const [masjidTime, setMasjidTime] = useState(props.masjid);

  if (!props.masjid) return (<></>);

  return (
    <>
    
      <div className="mosque-details grey-bg" key={props.masjid.id}
      >
        <div className="mosque-card-top d-flex align-items-start justify-content-between">
          {/* <div className="mosque-img">
            <img src={tstimg} />


          </div> */}
          <div className="mosque-adress">
            <h3 className="mosque-name">
              {props.masjid.name}
              {/* <a target='_blank' href={`https://www.google.com/maps/dir/?api=1&destination=${props.masjid.latitude},${props.masjid.longitude}`}><FontAwesomeIcon icon={faPersonWalking } style={{  fontSize: '24px' }} /><span> Get Direction</span></a> */}

            </h3>


            <div className="adress">
              {/* <i className="fa-solid fa-location-dot" style={{ color: "indianred" }}></i> */}
              <div className="adress-pointer">
                {props.masjid.address}
              </div>
            </div>
            <div className="distance">
              {/* <i className="fa-solid fa-people-arrows" style={{ color: "green" }}></i> */}
              {props.masjid.distance_to_masjid ? (
                <div>
                  <strong>Distance:</strong>{props.masjid.distance_to_masjid}
                </div>
              ) : "Turn location on for distance"}
            </div>
          </div>
          <div className='d-flex align-items-start'>
            <FavToggleButton
              masjidId={props.masjid.id}
            />

            <EditMasjidButton
              masjid={props.masjid}
              onEdit={(masjid) => setMasjidTime(masjid)}
            />

            {/* <EditMasjidButton masjid={props.masjid} /> */}
            <Button variant="link" target='_blank' href={`https://www.google.com/maps/dir/?api=1&destination=${props.masjid.latitude},${props.masjid.longitude}`} >
              <FontAwesomeIcon icon={faPersonWalking} />
            </Button>
          </div>
        </div>
        <div className="prayer-times-container">
          <div className="prayer-time-item" style={{ background: "#0b7955" }}>
            <div className="prayer-label">Fajr</div>
            <div className="prayer-time">{masjidTime.fajr} AM</div>
          </div>
          <div className="prayer-time-item" style={{ background: "#0b7955" }}>
            <div className="prayer-label">Dhuhr</div>
            <div className="prayer-time">{masjidTime.zohar} PM</div>
          </div>
          <div className="prayer-time-item" style={{ background: "#0b7955" }}>
            <div className="prayer-label">Asr</div>
            <div className="prayer-time">{masjidTime.asar} PM</div>
          </div>
          <div className="prayer-time-item" style={{ background: "#0b7955" }}>
            <div className="prayer-label">Maghrib</div>
            <div className="prayer-time">{masjidTime.maghrib} PM</div>
          </div>
          <div className="prayer-time-item" style={{ background: "#0b7955" }}>
            <div className="prayer-label">Isha</div>
            <div className="prayer-time">{masjidTime.isha} PM</div>
          </div>
          <div className="prayer-time-item" style={{ background: "#0b7955" }}>
            <div className="prayer-label">Juma</div>
            <div className="prayer-time">{masjidTime.juma}</div>
          </div>
        </div>

      </div>
    </>
  );
}

export default PhoneMasjidCard;
