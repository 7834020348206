import React, { useState } from 'react';
import prayTimes from 'praytimes';

function RamzanTime() {
    const [cityName, setCityName] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [timetable, setTimetable] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'cityName') {
            setCityName(value);
        } else if (name === 'latitude') {
            setLatitude(value);
        } else if (name === 'longitude') {
            setLongitude(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const times = calculateTimetable(latitude, longitude);
            setTimetable(times);
        } catch (error) {
            console.error('Error fetching timetable:', error);
            // Handle error, e.g., display error message to the user
        }
    };

    const calculateTimetable = (latitude, longitude) => {
        const pt = new prayTimes();
        pt.setMethod('ISNA'); // Set the calculation method to ISNA
        const times = pt.getTimes(new Date(), [parseFloat(latitude), parseFloat(longitude)], '+00:00', 'auto', 'auto');
        return times;
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <input type="text" name="cityName" value={cityName} onChange={handleChange} placeholder="Enter city name" required />
                <input type="text" name="latitude" value={latitude} onChange={handleChange} placeholder="Enter latitude" required />
                <input type="text" name="longitude" value={longitude} onChange={handleChange} placeholder="Enter longitude" required />
                <button type="submit" disabled={!cityName || !latitude || !longitude}>Get Timetable</button>
            </form>
            {timetable && (
                <div>
                    <h2>Timetable for {cityName}</h2>
                    <p>Sehri: {timetable.fajr}</p>
                    <p>Iftar: {timetable.maghrib}</p>
                </div>
            )}
        </div>
    );
}

export default RamzanTime;
