import React from "react";
import "./searchinput.scss";

function SearchInput(props: any) {
  // const [cityInput, setCityInput] = useState('');

  const cityLocation = (e: any) => {
    console.log(e.target.value);
    console.log(e.target);
    console.log(e);
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default form submission behavior
      return;
    }
    // setCityInput(e.target.value);
    // const input = document.getElementById('location');
    const input = document.getElementById(
      "location"
    ) as HTMLInputElement | null;
    if (input) {
      const autocomplete = new window.google.maps.places.Autocomplete(input, {
        types: ["geocode"], // You can specify the types of predictions you want (e.g., cities, addresses, etc.)
        componentRestrictions: {country: "in"}
      });

      // Listen for place selection
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();  
        if (!place || !place.geometry) {
            console.error("Place or geometry information is missing.");
            return;
        }
        const { geometry } = place;
        const { location } = geometry || {};
        const latitude = location?.lat();
        const longitude = location?.lng();

        props.setUserLocation({ latitude, longitude });
      });
    } else {
      console.error("Element with id 'location' not found.");
    }
  };

  return (
    <input
      type="text"
      name="location"
      id="location"
      onKeyDown={cityLocation}
      className="search-box"
      placeholder="Enter city name..."
    />
  );
}

export default SearchInput;
