import React from 'react';
import './about.css';

function AboutCom() {
    return (
        <>
            <header>
                <h1>About Us</h1>
            </header>
            <div className="container">
            
                <section>
                    <p>Welcome to <span style={{ fontWeight: 'bold' }}>Masjid Near Me</span> – your comprehensive directory for locating nearby mosques and prayer times.</p>

                    <p>At <span style={{ fontWeight: 'bold' }}>Masjid Near Me</span>, we understand the importance of finding a place to pray and staying connected with your local Muslim community. Our platform is designed to provide you with accurate and up-to-date information about nearby mosques and their prayer timings.</p>

                    <p>Whether you're traveling to a new city or simply looking for a convenient place to pray, <span style={{ fontWeight: 'bold' }}>Masjid Near Me</span> is here to help. Our user-friendly interface allows you to easily search for mosques in your area and view their prayer schedules.</p>

                    <p>What sets us apart is our unique feature that empowers users to update prayer times for their local mosques. By registering on our website, you can contribute to keeping the information on our platform accurate and reliable for fellow worshippers.</p>

                    <p>Our mission is to facilitate easy access to prayer facilities for Muslims around the world. Whether you're a frequent traveler or a local resident, <span style={{ fontWeight: 'bold' }}>Masjid Near Me</span> is your go-to resource for finding nearby mosques and prayer times.</p>

                    <p>Join our community today and discover the convenience of staying connected with your local Muslim community through <span style={{ fontWeight: 'bold' }}>Masjid Near Me</span>.</p>
                </section>
            </div>
        </>
    )
}

export default AboutCom;