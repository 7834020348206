import React, { useEffect } from 'react';
import './register.css';
import { useNavigate } from 'react-router-dom';

import LoginForgetPasswordCom from '../Login/LoginForgetPasswordCom';

import RegisterForm from './RegisterForm';
import SocialRegister from './SocialRegister';
import RegisterSeparator from './RegisterSeparator';
import RegisterHeader from './RegisterHeader';
import LoginHereCom from './LoginHereCom';
function RegisterComponent () {
   
  const Navigate = useNavigate();




  useEffect(() => {
    if (localStorage.getItem('token')) {
      Navigate('/');
    }
  }, []);

  return (
    <div className="login-form pl-lg-4">
    <RegisterHeader/>
    <RegisterForm/>
    <LoginForgetPasswordCom/>
    <LoginHereCom/>
    <RegisterSeparator/>
    <SocialRegister/>
    </div>
  );
  
}

export default RegisterComponent;