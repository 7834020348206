import { post } from "../hooks/useHttp";
export const editMasjidTime = (formData: any) => {
    // console.log('editAction');
    // console.log(formData);
    return post(`/masjid-edit`, formData);
}

export const editMasjidRequest = (masjidId: any) => {
    const requestData = { masjidId: masjidId };
    return post(`/masjid-edit-request`, requestData);
}