import React, { useEffect } from "react";
import './editpopup.scss';
import dayjs from 'dayjs';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SubmitHandler } from 'react-hook-form';
import { FieldValues } from 'react-hook-form';

import { editMasjidTime } from "../../../Services/editMasjidService";

interface MasjidData {
    fajr: string;
    zohar: string;
    asar: string;
    maghrib: string;
    isha: string;
    juma: string;
    tarabhi: string;
    masjidId: string;
    name: string;
    id:number
  }
  
  interface EditMasjidPopupProps {
    showModal: boolean;
    closePopup: () => void;
    masjidData: MasjidData;
    toaster: (message: string) => void;
    setMasjidTime: React.Dispatch<React.SetStateAction<MasjidData>>;
  }
  interface EditMasjidTimeResponse {
    success: boolean;
    message: string;
    // Add other properties if present in the response
  }
function EditPopup(props:EditMasjidPopupProps){
    const {
        handleSubmit,
        register,
        control,
        setValue,
        formState: { errors },
        // setError,
      } = useForm();
    
      useEffect(() => {
        if (props) {
          if (props.masjidData.fajr) {
            const timeString = props.masjidData.fajr;
            const [hours, minutes] = timeString.split(':').map(Number);
            const result = dayjs().set('hour', hours).set('minute', minutes);
            setValue('fajr', result);
          }
          if (props.masjidData.zohar) {
            console.log('props.masjid.zohar');
            const timeString = props.masjidData.zohar;
            const [hours, minutes] = timeString.split(':').map(Number);
            const result = dayjs().set('hour', hours).set('minute', minutes);
            setValue('zohar', result);
          }
    
          if (props.masjidData.asar) {
            const timeString = props.masjidData.asar;
            const [hours, minutes] = timeString.split(':').map(Number);
            const result = dayjs().set('hour', hours).set('minute', minutes);
            setValue('asar', result);
          } 
    
          if (props.masjidData.maghrib) {
            const timeString = props.masjidData.maghrib;
            const [hours, minutes] = timeString.split(':').map(Number);
            const result = dayjs().set('hour', hours).set('minute', minutes);
            setValue('maghrib', result);
          }
    
          if (props.masjidData.isha) {
            const timeString = props.masjidData.isha;
            const [hours, minutes] = timeString.split(':').map(Number);
            const result = dayjs().set('hour', hours).set('minute', minutes);
            setValue('isha', result);
          }
    
          if (props.masjidData.juma) {
            const timeString = props.masjidData.juma;
            const [hours, minutes] = timeString.split(':').map(Number);
            const result = dayjs().set('hour', hours).set('minute', minutes);
            setValue('juma', result);
          }
    
          if (props.masjidData.tarabhi) {
            const timeString = props.masjidData.tarabhi;
            const [hours, minutes] = timeString.split(':').map(Number);
            const result = dayjs().set('hour', hours).set('minute', minutes);
            setValue('tarabhi', result);
          }
          if (props.masjidData.masjidId) {
            const result = props.masjidData.masjidId;
            setValue('masjidId', result);
          }
        }
      }, [])
    
      const onSubmit: SubmitHandler<FieldValues> = async (formData) => {
        const masjidFormData = formData as MasjidData;
        editAction(masjidFormData);
      };

      const editAction = async (formData:MasjidData) => {
        try {
          formData.asar = dayjs(formData.asar).format('HH:mm');
          formData.fajr = dayjs(formData.fajr).format('HH:mm');
          formData.zohar = dayjs(formData.zohar).format('HH:mm');
          formData.maghrib = dayjs(formData.maghrib).format('HH:mm');
          formData.isha = dayjs(formData.isha).format('HH:mm');
          formData.juma = dayjs(formData.juma).format('HH:mm');
          formData.tarabhi = dayjs(formData.tarabhi).format('HH:mm');
          const res = (await editMasjidTime(formData)) as any;
          const responseData = res.data;
          const { success }: EditMasjidTimeResponse = responseData; 
          if (success == true) {
            props.setMasjidTime(prevData => ({
              ...prevData,  // Copy all existing properties
              fajr: formData.fajr,
              zohar: formData.zohar,
              asar: formData.asar,
              maghrib: formData.maghrib,
              isha: formData.isha,
              juma: formData.juma,
              tarabhi: formData.tarabhi,
              masjidId: formData.masjidId,
            }));
            props.toaster(res.data.message);
            props.closePopup();
          }
    
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    
    return(
        <Modal show={props.showModal} onHide={props.closePopup} id="editPopup" centered>
        <Modal.Header closeButton>
          <Modal.Title>{props.masjidData.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          {/* Add your modal content here */}
          <Form onSubmit={handleSubmit(onSubmit)} >
            <div className='row'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="col-12 mb-3">
                  {/* <Form.Label>Fajr Time</Form.Label> */}
                  <Form.Group controlId="formFajr">
                    <Controller
                      name="fajr"
                      control={control}
                      render={({ field }) => (
                        <>
                          <MobileTimePicker
                          className="mobile_time_picker"
                            label='Fajr Time'
                            minTime={dayjs().set('hour', 4).set('minute', 0)}
                            maxTime={dayjs().set('hour', 7).set('minute', 0)}
                            ampm={false}
                            value={field.value} // Use the value prop to control the time picker
                            onChange={(newValue) => field.onChange(newValue)}
                            // onBlur={(newValue) => field.onBlur(newValue)}
                          />
                        </>
                      )}
                    />
                    <span className="text-danger"> {errors.fajr && (errors.fajr.message as React.ReactNode)}</span>
                  </Form.Group>
                </div>
                <div className="col-12 mb-3">
                  {/* <Form.Label>zuhar Time</Form.Label> */}
                  <Form.Group controlId="formZuhar">
                    <Controller
                      name="zohar"
                      control={control}
                      render={({ field }) => (
                        <>
                        <MobileTimePicker
                          className="mobile_time_picker"
                          label='Zohar Time'
                          minTime={dayjs().set('hour', 0).set('minute', 0)}  // Set the minTime prop to 5:00 PM
                          maxTime={dayjs().set('hour', 3).set('minute', 0)}  // Set the maxTime prop to 7:00 PM
                          ampm={false}
                          {...field}
                        />
                        </>
                      )}
                    />
                    <span className="text-danger">{errors.zohar && (errors.zohar.message as React.ReactNode)}</span>
                  </Form.Group>
                </div>
                <div className="col-12 mb-3">
                  {/* <Form.Label>Jumu'ah Time</Form.Label> */}
                  <Form.Group controlId="formJuma">
                    <Controller
                      name="juma"
                      control={control}
                      render={({ field }) => (
                        <MobileTimePicker
                        className="mobile_time_picker juma_time"

                          label='juma Time'
                          minTime={dayjs().set('hour', 12).set('minute', 30)}  // Set the minTime prop to 12:30 PM
                          maxTime={dayjs().set('hour', 15).set('minute', 0)}  // Set the maxTime prop to 3:00 PM
                          ampm={false}
                          {...field}
                        />
                      )}
                    />
                    <span className="text-danger">{errors.juma && (errors.juma.message as React.ReactNode)}</span>
                  </Form.Group>
                </div>
                <div className="col-12 mb-3">
                  {/* <Form.Label>Asr Time</Form.Label> */}
                  <Form.Group controlId="formAsr">
                    <Controller
                      name="asar"
                      control={control}  
                      render={({ field }) => (
                        <MobileTimePicker
                        className="mobile_time_picker"
                          label='Asar Time'
                          minTime={dayjs().set('hour', 3).set('minute', 30)}  // Set the minTime prop to 5:00 PM
                          maxTime={dayjs().set('hour', 6).set('minute', 0)}  // Set the maxTime prop to 7:00 PM
                          ampm={false}
                          {...field}
                        />
                      )}
                    />
                    <span className="text-danger">{errors.asar && (errors.asar.message as React.ReactNode)}</span>
                  </Form.Group>
                </div>
                <div className="col-12 mb-3">
                  {/* <Form.Label>Maghrib Time</Form.Label> */}
                  <Form.Group controlId="formMaghrib">
                    <Controller
                      name="maghrib"
                      control={control}
                      render={({ field }) => (
                        <MobileTimePicker
                        className="mobile_time_picker"
                          label='Maghrib Time'
                          minTime={dayjs().set('hour', 5).set('minute', 0)}  // Set the minTime prop to 5:00 PM
                          maxTime={dayjs().set('hour', 7).set('minute', 30)}  // Set the maxTime prop to 7:00 PM
                          ampm={false}
                          {...field}
                        />
                      )}
                    />
                    <span className="text-danger">{errors.maghrib && (errors.maghrib.message as React.ReactNode)}</span>
                  </Form.Group>
                </div>

                <div className="col-12 mb-3">
                  {/* <Form.Label>Isha Time</Form.Label> */}
                  <Form.Group controlId="formIsha" className='mb-3'>
                    <Controller
                      name="isha"
                      control={control}
                      render={({ field }) => (
                        <MobileTimePicker
                        className="mobile_time_picker"
                          label='Isha Time'
                          minTime={dayjs().set('hour', 7).set('minute', 0)}  // Set the minTime prop to 5:00 PM
                          maxTime={dayjs().set('hour', 9).set('minute', 30)}  // Set the maxTime prop to 7:00 PM
                          ampm={false}
                          {...field}
                        />
                      )}
                    />
                    <span className="text-danger">{errors.isha && (errors.isha.message as React.ReactNode)}</span>
                  </Form.Group>
                </div>
                <Form.Group controlId="formMasjidId" className="d-none">
                  <Form.Control
                    type="hidden"
                    {...register('masjidId', { value: props.masjidData.id })}
                  />
                </Form.Group>
              </LocalizationProvider>
              <div className='col-6 mobile_time_picker text-center'>
                <Button variant="success" type="submit">
                  Save Changes
                </Button>
              </div>

              {/* <ToastContainer /> */}
            </div>

          </Form>
        </Modal.Body>
      </Modal>
    )
}

export default EditPopup;