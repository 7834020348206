const TOKEN_KEY = "token";

const Auth = () => {
  const storeTokens = (token: string) => {
    localStorage.setItem(TOKEN_KEY, token);
  };
  const getAuthToken = (): string | null | undefined => {
    const token = localStorage.getItem(TOKEN_KEY);
    return token ?? undefined;
  };
  const clearSession = () => {
    localStorage.removeItem(TOKEN_KEY);
  };

  const clearAllSession = () => {
    localStorage.clear();
  };
  return {
    storeTokens,
    getAuthToken,
    clearSession,
    clearAllSession,
  };
};

export default Auth;
