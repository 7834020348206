import React from 'react'

import LogoutCom from '../../components/Logout/LogoutCom'

function Logout() {
  return (
   <LogoutCom></LogoutCom>
  )
}

export default Logout