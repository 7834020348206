import React from "react";
import { NavLink } from "react-router-dom";
function SignUpHereCom(){
    return (
        <p>
        need an account?{" "}
        <NavLink className="" to="/register">
          Sign up here
        </NavLink>{" "}
      </p>
    )
}

export default SignUpHereCom;