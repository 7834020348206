import React, { useState, useCallback } from "react";
import { useQuery } from "react-query";
import { Skeleton } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";

import PhoneMasjidCard from "../../components/PhoneVersion/MasjidCard/PhoneMasjidCard";
import SearchInput from "../../components/Input/SearchInput";
import DetectButton from "../../components/DetectButton/DetectButton";
import { getMajids } from "../../Services/masjidService";

interface Masjid {
  id: any; // Change the type of id as per your data structure
  // Other properties of Masjid
}
//setMasjids([])
function FindMasjidPhone() {
  const [userLocation, setUserLocation] = useState(null);
  const [masjids, setMasjids] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const loadMosque = useCallback(async () => {
    const res = (await getMajids(currentPage, userLocation)) as any;
    setMasjids((prevMasjids: any) => [...prevMasjids, ...res.results]);
    setTotalPages(res.pagination.total_pages);
  }, [currentPage, userLocation]);

  useQuery({
    queryKey: ["get-mosque", currentPage, userLocation],
    enabled: true,
    refetchOnWindowFocus: false,
    queryFn: loadMosque,
  });

  const fetchMoreData = () => {
    setCurrentPage((prevPage) => prevPage + 1); // Increment page number
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 bg-white">
          <div>
            <div className="search-container">
              <SearchInput
                setUserLocation={setUserLocation}
                setMasjids={setMasjids}
              />
              <DetectButton
                setUserLocation={setUserLocation}
                setMasjids={setMasjids}
              />
            </div>
          </div>
          <div className="mosques" id="scrollableDiv">
            <InfiniteScroll
              dataLength={masjids.length}
              next={fetchMoreData}
              hasMore={totalPages >= currentPage}
              loader={
                <div>
                  {/* Skeleton loading indicators */}
                  <Skeleton variant="text" width={200} />
                  <Skeleton variant="rectangular" width="100%" height={150} />
                  {/* Repeat as needed */}
                </div>
              }
              pullDownToRefreshThreshold={100}
              scrollableTarget="scrollableDiv"
            >
              {masjids.map((masjid: Masjid) => (
                <PhoneMasjidCard key={masjid.id} masjid={masjid} />
              ))}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FindMasjidPhone;
