import React from "react";
import { NavLink} from 'react-router-dom';
 function RegisterHeader(){
    return(
        <>
        <div className="logo">
          <NavLink className="" to="/">Masjid Near Me</NavLink>
        </div>
         <h1>Register Now In Masjid Near Me For More Information</h1>
        </>
    )
 }

 export default RegisterHeader;