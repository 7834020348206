import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from './components/Header/Header';
import Register from './views/register/Register';
import Login from './views/login/Login';
import FindMasjid from './views/findmasjid/FindMasjid';
import Logout from './views/Logout/Logout';
import About from './views/about/About';
import AddMasjid from './views/addmasjid/AddMasjid';
import FindMasjidPhone from './views/phoneVersion/FindMasjidPhone';
import RamzanTime from './views/ramzantime/RamzanTime';
import FavouriteMasjid from './views/favouritemasjids/FavouriteMasjid';
import ResetProfile from './views/resetProfile/ResetProfile';
import ChangePassword from './views/changePassword/ChangePassword';
import ForgetPassword from './views/forgetPassword/ForgetPassword'
import ResetPassword from './views/ResetPassword/ResetPassword';
function App() {
    return (
        // <Router>
          <div>
            <Header />
            <Routes>
              <Route path="/" element={<FindMasjid />} />
              <Route path="/about" element={<About />} /> 
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/add-masjid" element={<AddMasjid />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/phone" element={<FindMasjidPhone />} />
              <Route path="/ramazan-time" element={<RamzanTime />} />
              <Route path="/favourite-masjids" element={<FavouriteMasjid />} />
              <Route path="/edit-profile" element={<ResetProfile/>} />
              <Route path="/change-password" element={<ChangePassword/>} />
              <Route path="/forget-password" element={<ForgetPassword/>} />
              <Route path="/reset-password" element={<ResetPassword/>} />
            </Routes>
          </div>
        // </Router>
      );
}

export default App