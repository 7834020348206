import React from "react";
import './detectbutton.scss';
function DetectButton(props:any){

    const detectLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude, longitude } = position.coords;
                    props.setUserLocation({ latitude, longitude });
                },
                error => {
                    props.setUserLocation(null);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    return(
        <button type="submit" className="search-button" onClick={detectLocation}><span>Detect</span></button>
    )
}

export default DetectButton;