import React,{useState} from "react";
import "./updatepassword.scss";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { changePassword } from "../../Services/clientService";

function UpdatePassword() {
const [showToaster, setShowToaster] = useState(false);
const Navigate = useNavigate();
const notify = (message: string) => {
    setShowToaster(true);
    toast.success(message, {
        onClose: () => Navigate("/") // Navigate to the "/" page when the toaster is closed
    });
  };
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm();

  const onSubmit = async (formData: any) => {
    registerAction(formData);
  };
  const registerAction = async (formData: any) => {
    try {
      const res = (await changePassword(formData)) as any;
      const success = res.success;
      console.log(res);
      if(success){
        notify(res.message);
        // setTimeout(() => {
        //   Navigate('/');
        // }, 3000);
      }else {
        for (const field in res.errors) {
          setError(field, {
            type: 'manual',
            message: res.errors[field][0],
          });
        }
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <>
      <div className="bg-light">
        <header>
          <h1 className="text-white">Change Password</h1>
        </header>
        <div className="main-column__inner">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="column-content">
              <div className="form-group">
                <label className="control-label">
                  Current Password
                  <span className="trf-control-label-required">*</span>
                </label>
                <div className="d-flex position-relative">
                  <input
                    className="form-control me-2"
                    type="password"
                    placeholder="Current Password"
                    {...register("current_password", {
                      required: "Current password is required",
                    })}
                  />
                  <span className="input-icon">
                    <FontAwesomeIcon icon={faLock} />
                  </span>
                </div>
              </div>
              {errors.current_password &&
                typeof errors.current_password.message === "string" && (
                  <p className="error">{errors.current_password.message}</p>
                )}
              <div className="form-group">
                <label className="control-label">
                  New Password
                  <span className="trf-control-label-required">*</span>
                </label>
                <div className="d-flex position-relative">
                  <input
                    className="form-control me-2"
                    type="password"
                    placeholder="New Password"
                    {...register("password", {
                      required: "password is required",
                    })}
                  />
                  <span className="input-icon ">
                    <FontAwesomeIcon icon={faLock} />
                  </span>
                </div>
              </div>

              {errors.password &&
                typeof errors.password.message === "string" && (
                  <p className="error">{errors.password.message}</p>
                )}
              <div className="form-group">
                <label className="control-label">
                  Confirm Password
                  <span className="trf-control-label-required">*</span>
                </label>
                <div className="d-flex position-relative">
                  <input
                    className="form-control me-2"
                    type="password"
                    placeholder="Re-enter Password"
                    {...register("confirm_password", {
                      required: "Confirm Password is required",
                    })}
                  />
                  <span className="input-icon">
                    <FontAwesomeIcon icon={faLock} />
                  </span>
                </div>
              </div>

              {errors.confirm_password &&
                typeof errors.confirm_password.message === "string" && (
                  <p className="error">{errors.confirm_password.message}</p>
                )}

              <div className="form-group mb-2">
                <small>
                  <span className="trf-control-label-required">*</span>
                  Indicates required field
                </small>
              </div>

              <button className="login-btn" type="submit">Submit</button>
            </div>
          </form>
          {showToaster && <ToastContainer />}
        </div>
      </div>
    </>
  );
}

export default UpdatePassword;
