import React from 'react'

import firstImage from './images/one.jpg';
import secImage from './images/two.jpg';
import './loginslider.css';
import thirdImage from './images/four.jpg';
function LoginSlider() {
    return (
        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators">
            </ol>
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src={firstImage} className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item">
                    <img src={secImage} className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item">
                    <img src={thirdImage} className="d-block w-100" alt="..." />
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-target="#carouselExampleIndicators" data-slide="prev">
                <span className="sr-only"></span>
            </button>
            <button className="carousel-control-next" type="button" data-target="#carouselExampleIndicators" data-slide="next">
                <span className="sr-only"></span>
            </button>
        </div>
    )
}

export default LoginSlider