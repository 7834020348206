import React, {useState} from "react";
import './register.css';
import axios from 'axios';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin} from '@react-oauth/google';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // faFacebookF,
  faGoogle,
} from '@fortawesome/free-brands-svg-icons';

import { setUser } from "../../store/slices/authUserSlice";
import { setToken } from "../../store/slices/tokenSlice";
import Auth from "../../Services/Auth";

function SocialRegister(){
    
    const dispatch = useDispatch();
    const auth = Auth();
    const [errorMessage, setErrorMessage] = useState('');
    const googleLoginUrl = process.env.REACT_APP_API + '/api/auth/google/callback';
    const Navigate = useNavigate();
    const login = useGoogleLogin({
        onSuccess: tokenResponse => handleGoogleLogin(tokenResponse),
        flow: 'implicit',
      });
      const handleGoogleLogin = async (tokenResponse:any) => {
        const googleAccessToken = tokenResponse.access_token; // Get Google Access Token
        
        try {
          const response = await axios.post(googleLoginUrl, {
            accessToken: googleAccessToken,
          });
          
          const { success, data } = response.data;
          if (success) {
            const { token } = data;
            dispatch(setUser(data.user));
            dispatch(setToken(token));
            auth.storeTokens(token);
            setTimeout(() => {
              Navigate('/');
            }, 1000);
          } else {
            setErrorMessage('Credentials don\'t match');
          }
        } catch (error) {
          setErrorMessage('Google login failed error');
        }
      };
    return(
        <>  
            {errorMessage && <p className="error">{errorMessage}</p>}
            <p>Sign in with Gmail account</p>
            <div className="social-icon">
            <button type="button" onClick={() => login()} ><FontAwesomeIcon icon={faGoogle} /></button>
            {/* <button type="button">
                <FontAwesomeIcon icon={faFacebookF} />
            </button> */}
            {/* <button type="button" src=""><i className="fa-brands fa-google">
                    <GoogleLogin shape='circle' type='icon' theme='outline' onSuccess={handleGoogleLogin} onError={()=> {
                        console.log('Google Login Failed');
                        }}
                        />
                </i></button> */}
            </div>
        </>
    )
}

export default SocialRegister;