import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthOld from '../../Services/AuthOld';

const Logout = () => {
    const Navigate = useNavigate();
    const [showConfirmation, setShowConfirmation] = useState(true);
    const handleLogout = async () => {
    
      AuthOld.logout();
    Navigate('/')
  };

  const handleCancel = () =>{
    setShowConfirmation(false);
    setTimeout(() => {
      Navigate('/');
    }, 500);
  }

  return (
    <div>
        {showConfirmation && (
            <div className="confirmation-modal">
                <p>Are you sure you want to log out?</p>
                <button onClick={handleLogout}>Yes</button>
                <button onClick={handleCancel}>Cancel</button>
            </div>
        )}
        
    </div>
  );
};

export default Logout;
